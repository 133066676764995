import React from 'react';

import styles from './styles.module.scss';

const htmlEncoder = (str) => str.replace(/ /g, '%20').replace(/\n/g, '%0D%0A%0D%0A');
const mailto = htmlEncoder('office@socket.net');
const subject = htmlEncoder('React Portal Issues');
const body = htmlEncoder([
  'Nature of Inquiry - Feedback or bug:',
  'Description:',
  'Expected outcome:',
  'Actual result:',
  'Steps to reproduce the issue (list all user inputs if possible):',
  'Affected Pages (URLs please):',
  'Who can we contact if we have additional questions:',
].join('\n'));
function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.links}>
        <a href="http://socket.net/residential/internet" target="_blank" rel="noreferrer">Residential</a>
        <a href="http://www.socket.net/business/internet" target="_blank" rel="noreferrer">Business</a>
        <a href="http://webmail.socket.net/" target="_blank" rel="noreferrer">Webmail</a>
        <a href="http://www.socket.net/contact" target="_blank" rel="noreferrer">Contact Us</a>
      </div>
      © Copyright 2025 Socket Holdings Corp. All rights reserved.
      <a href={`mailto:${mailto}?subject=${subject}&body=${body}`} target="_blank" rel="noreferrer">Report an issue or give feedback</a>
    </div>
  );
}

export default Footer;
